// import lowerdev from '@/service/lowerdev'
import { get } from "@/utils/apiBase.js";
import domain from "@/service/domain";
import BigImage from "@@/luckycards/bigImage.vue";
import { mapState } from "vuex";
export default {
  name: "openboxlist",
  data() {
    return {
      mes: "",
      list: [],
      bigImageVisible: false,
      bigInitIndex: 0,
      img_list: []
    };
  },
  components: {
    BigImage
  },
  watch: {},
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  props: ["orderId", "orderInfo"],
  mounted() {
    this.getlist();
  },
  methods: {
    showBigImage(index) {
      this.img_list = [];
      this.list.forEach(ele => {
        ele.card_back_img_url = ele.back_image_url;
        ele.card_img_url = ele.card_icon;
        ele.showBackImg = false;
        this.img_list.push(ele);
      });
      this.bigInitIndex = index;
      this.bigImageVisible = true;
    },
    toProject() {
      location.href = `${domain.wap}/luckycards/container`;
    },
    close() {
      this.$emit("close");
    },
    getlist() {
      // console.log(this.orderInfo)
      const order = {
        url: "/apis/mdorder/cards",
        method: "get",
        props: {
          order_id: this.orderId,
          page: 1,
          page_size: 999
        }
      };
      get(order.url, order.props).then(e => {
        if (e.status == 0) {
          this.list = e.data.list;
        }
      });
    },
    dialogBack() {
      this.$emit("dialogBack");
    },
    op(type) {
      this.$emit("toOp", type);
    }
  }
};