import { createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
// import { ref } from 'vue'
// import env from "md-base-tools/env";
import { domClientWidth, domPixelRatio, env } from "md-base-tools/env";
import domain from "@/service/domain";
import lowerdev from "@/service/lowerdev";
import MDRouter from "@/service/router";
export default {
  name: "orderHeader",
  props: {
    orderInfo: Object
  },
  data() {
    return {
      miniProgram: "",
      showAll: false
    };
  },
  setup(props, context) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const orderInfo = props.orderInfo;

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      orderInfo
    };
  },
  computed: {
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  mounted() {
    lowerdev.addbind(window, "scroll", this.reloadScroll);
    // eslint-disable-next-line eqeqeq
    this.miniProgram = sessionStorage.getItem("miniProgram") == "weixin";
  },
  methods: {
    openboxmore() {
      this.$emit("openboxmore");
    },
    openrefund() {
      this.$emit("openrefund");
    },
    toProject() {
      MDRouter.goProInfo(this.orderInfo.product_info.id);
    },
    replaceBr(str) {
      // eslint-disable-next-line no-control-regex
      var reg = new RegExp("\n", "g"); // g,表示全部替换。
      return str.replace(reg, " <br> ");
    },
    downApp() {
      this.showAll = true;
    },
    openApply() {
      sessionStorage.setItem('product_info', JSON.stringify(this.orderInfo.product_info));
      sessionStorage.setItem('address_info', JSON.stringify(this.orderInfo.address_info));
      this.$router.push(`/after_sale/apply/${this.orderInfo.order_id}`);
    }
  },
  render() {
    const orderInfo = this.orderInfo;
    const imgArr = () => {
      const A = [];
      if (orderInfo.trade_data.rew_logo && orderInfo.trade_data.rew_logo.length) {
        orderInfo.trade_data.rew_logo.map(e => {
          A.push(_createVNode("img", {
            "src": this.$imgUrlFilter(e, this.imgWidth),
            "alt": ""
          }, null));
        });
        return _createVNode("div", {
          "class": "rew_logo"
        }, [A]);
      }
    };
    const Aftersale = () => {
      if (orderInfo.aftersale_info && orderInfo.aftersale_info.status == 1) {
        return _createVNode("div", {
          "class": "aftersale_info",
          "onClick": () => {
            this.openApply();
          }
        }, [_createVNode("span", {
          "class": "icon-refund"
        }, null), _createVNode("span", null, [orderInfo.aftersale_info.title])]);
      }
    };
    return _createVNode("div", {
      "class": "zhongchou"
    }, [_createVNode("svg", {
      "class": "iconfont crowdfunding",
      "aria-hidden": "true"
    }, [_createVNode("use", {
      "xlink:href": "#icon-crowdfunding"
    }, null)]), _createVNode("div", {
      "class": "zc-title",
      "onClick": () => {
        this.toProject();
      }
    }, [_createVNode("div", {
      "class": "img"
    }, [_createVNode("img", {
      "src": this.$imgUrlFilter(orderInfo.product_info.wb_logo_1, this.imgWidth),
      "alt": ""
    }, null)]), _createVNode("div", {
      "class": "text"
    }, [orderInfo.product_info.name]), _createVNode("span", {
      "class": "nestmore"
    }, [_createVNode("div", {
      "class": "righticon",
      "aria-hidden": "true"
    }, null)])]), _createVNode("div", {
      "class": "trade_data"
    }, [(() => {
      if (orderInfo.trade_data.reward_id !== -3) {
        const rewardName = [];
        rewardName.push(_createVNode("div", {
          "class": "reward_name"
        }, [_createVNode("span", null, [_createTextVNode("\u56DE\u62A5\u6863\uFF1A\xA5"), orderInfo.trade_data.reward_money, _createTextVNode("\uFF08"), orderInfo.trade_data.reward_name, _createTextVNode("\uFF09")]), _createVNode("span", {
          "class": "num"
        }, [_createTextVNode("\xD7"), orderInfo.trade_data.apply_total])]));
        if (orderInfo.trade_data.is_customized) {
          rewardName.push(_createVNode("div", {
            "class": "reward_is_customized"
          }, [_createTextVNode("*\u8BE5\u4EA7\u54C1\u4E3A\u5B9A\u5236\u4EA7\u54C1\uFF0C\u4F17\u7B79\u6210\u529F\u540E\u4E0D\u652F\u6301\u65E0\u7406\u7531\u9000\u8D27\u9000\u6B3E")]));
        }
        if (orderInfo.trade_data.reward_day) {
          rewardName.push(_createVNode("div", {
            "class": "reward_day"
          }, [_createTextVNode("\u9884\u8BA1\u53D1\u653E\u65F6\u95F4\uFF1A"), orderInfo.trade_data.reward_day]));
        }
        return rewardName;
      } else {
        const Content = [];
        Content.push(_createVNode("div", {
          "class": "reward_name"
        }, [_createVNode("span", null, [orderInfo.trade_data.reward_name])]));
        // matching_amount
        if (orderInfo.order_type === 2) {
          if (orderInfo.status < 201) {
            Content.push(_createVNode("div", {
              "class": "together_detail two"
            }, [_createVNode("div", {
              "class": "item_left"
            }, [_createVNode("span", {
              "class": "c c1 active"
            }, null), _createVNode("span", {
              "class": "line activityF"
            }, [_createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null)]), _createVNode("span", {
              "class": "c c3"
            }, null)]), _createVNode("div", {
              "class": "item_right"
            }, [_createVNode("div", {
              "class": "item_col"
            }, [_createVNode("p", null, [_createTextVNode("\u9884\u4ED8\u91D1\u989D\uFF1A\xA5"), orderInfo.pay_amount]), _createVNode("p", null, [_createTextVNode("\u672A\u4ED8\u6B3E")])]), _createVNode("div", {
              "class": "item_col"
            }, [_createVNode("p", null, [_createTextVNode("\u5B9E\u9645\u914D\u6350")]), _createVNode("p", null, [_createTextVNode("\u672A\u751F\u6210")])])])]));
          } else {
            if (!orderInfo.matching_amount) {
              Content.push(_createVNode("div", {
                "class": "together_detail two"
              }, [_createVNode("div", {
                "class": "item_left"
              }, [_createVNode("span", {
                "class": "c c1 active"
              }, null), _createVNode("span", {
                "class": "line activityF"
              }, [_createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null)]), _createVNode("span", {
                "class": "c c3"
              }, null)]), _createVNode("div", {
                "class": "item_right"
              }, [_createVNode("div", {
                "class": "item_col"
              }, [_createVNode("p", null, [_createTextVNode("\u9884\u4ED8\u91D1\u989D\uFF1A\xA5"), orderInfo.pay_amount]), _createVNode("p", null, [_createTextVNode("\u5DF2\u4ED8\u6B3E")])]), _createVNode("div", {
                "class": "item_col"
              }, [_createVNode("p", null, [_createTextVNode("\u5B9E\u9645\u914D\u6350")]), _createVNode("p", null, [_createTextVNode("\u672A\u751F\u6210")])])])]));
            } else {
              if (!orderInfo.teamfund_refund_amount) {
                const B = [];
                if (orderInfo.difference_refund_amount) {
                  B.push(_createVNode("p", null, [_createTextVNode("\u5DF2\u9000\u6B3E \xA5"), orderInfo.difference_refund_amount]));
                } else {
                  B.push(_createVNode("p", null, null));
                }
                Content.push(_createVNode("div", {
                  "class": "together_detail two"
                }, [_createVNode("div", {
                  "class": "item_left"
                }, [_createVNode("span", {
                  "class": "c c1"
                }, null), _createVNode("span", {
                  "class": "line activity"
                }, [_createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null)]), _createVNode("span", {
                  "class": "c c3 active"
                }, null)]), _createVNode("div", {
                  "class": "item_right"
                }, [_createVNode("div", {
                  "class": "item_col"
                }, [_createVNode("p", null, [_createTextVNode("\u9884\u4ED8\u91D1\u989D\uFF1A\xA5"), orderInfo.pay_amount]), _createVNode("p", null, [_createTextVNode("\u5DF2\u4ED8\u6B3E")])]), _createVNode("div", {
                  "class": "item_col"
                }, [_createVNode("p", null, [_createTextVNode("\u5B9E\u9645\u914D\u6350\uFF1A\xA5"), orderInfo.matching_amount]), B])])]));
              } else {
                Content.push(_createVNode("div", {
                  "class": "together_detail three"
                }, [_createVNode("div", {
                  "class": "item_left"
                }, [_createVNode("span", {
                  "class": "c c1"
                }, null), _createVNode("span", {
                  "class": "line"
                }, [_createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null)]), _createVNode("span", {
                  "class": "c c2"
                }, null), _createVNode("span", {
                  "class": "line activity"
                }, [_createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null), _createVNode("span", null, null)]), _createVNode("span", {
                  "class": "c c3 active"
                }, null)]), _createVNode("div", {
                  "class": "item_right"
                }, [_createVNode("div", {
                  "class": "item_col"
                }, [_createVNode("p", null, [_createTextVNode("\u9884\u4ED8\u91D1\u989D\uFF1A\xA5"), orderInfo.pay_amount]), _createVNode("p", null, [_createTextVNode("\u5DF2\u4ED8\u6B3E")])]), _createVNode("div", {
                  "class": "item_col"
                }, [_createVNode("p", null, [_createTextVNode("\u5B9E\u9645\u914D\u6350\uFF1A\xA5"), orderInfo.matching_amount, _createTextVNode(" ")]), _createVNode("p", null, [_createTextVNode("\u5DF2\u9000\u6B3E \xA5"), orderInfo.difference_refund_amount])]), _createVNode("div", {
                  "class": "item_col"
                }, [_createVNode("p", null, [_createTextVNode("\u914D\u6350\u9000\u6B3E"), _createVNode("span", {
                  "class": "icon",
                  "onClick": () => {
                    this.openrefund();
                  }
                }, null)]), _createVNode("p", null, [_createTextVNode("\u5DF2\u9000\u6B3E \xA5"), orderInfo.teamfund_refund_amount])])])]));
              }
            }
          }
        }
        return Content;
      }
    })(), (() => {
      if (orderInfo.trade_data.reward_contents) {
        return _createVNode("div", {
          "class": "p",
          "style": "white-space:pre-line"
        }, [orderInfo.trade_data.reward_contents]);
      }
    })(), (() => {
      if (orderInfo.trade_data.reward_pack_desc && orderInfo.trade_data.reward_pack_desc.length) {
        const Arrtext = [];
        orderInfo.trade_data.reward_pack_desc.map(e => {
          Arrtext.push(_createVNode("p", null, [e]));
        });
        return _createVNode("div", null, [_createVNode("div", {
          "class": "openbox-text"
        }, [Arrtext])]);
      }
    })(), _createVNode(imgArr, null, null), _createVNode(Aftersale, null, null)]), (() => {
      if (orderInfo.trade_data.reward_pack_desc && orderInfo.trade_data.reward_pack_desc.length) {
        const ArrLi = [];
        if (orderInfo.pack_info && orderInfo.pack_info.pack_list) {
          orderInfo.pack_info.pack_list.map((e, idx) => {
            if (idx < 3) {
              ArrLi.push(_createVNode("div", {
                "class": "openbox-li"
              }, [_createVNode("div", {
                "class": "unpacking_type " + "unpacking_type" + e.unpacking_type
              }, [_createTextVNode("\u9690\u85CF\u6B3E")]), _createVNode("div", {
                "class": "img"
              }, [_createVNode("img", {
                "src": e.unpacking_icon
              }, null)]), _createVNode("div", {
                "class": "name"
              }, [e.unpacking_name])]));
            }
          });
          return _createVNode("div", null, [_createVNode("div", {
            "class": "openbox-result"
          }, [_createVNode("div", {
            "class": "openbox-title"
          }, [_createVNode("div", null, [_createTextVNode("\u62C6\u76D2\u7ED3\u679C")]), _createVNode("div", {
            "class": "num",
            "onClick": () => {
              this.openboxmore();
            }
          }, [_createTextVNode("\u67E5\u770B\u5168\u90E8 ("), orderInfo.pack_info.pack_total, _createTextVNode(")"), _createVNode("svg", {
            "class": "iconfont svg-icon",
            "aria-hidden": "true"
          }, [_createVNode("use", {
            "xlink:href": "#icon-nestmoreweibo"
          }, null)])])]), _createVNode("div", {
            "class": "openbox-ul",
            "onClick": () => {
              this.openboxmore();
            }
          }, [ArrLi])])]);
        }
      }
    })(), (() => {
      const ArrLi = [];
      if (orderInfo.card_info && orderInfo.card_info.pack_list) {
        orderInfo.pack_info.pack_list.map((e, idx) => {
          if (idx < 3) {
            if (e.back_image_url) {
              ArrLi.push(_createVNode("div", {
                "class": "openbox-li"
              }, [_createVNode("div", {
                "class": "unpacking_type " + "unpacking_type_card"
              }, [_createTextVNode("\u53CC\u9762\u5361")]), _createVNode("div", {
                "class": "img"
              }, [_createVNode("img", {
                "src": e.card_icon
              }, null), _createVNode("div", {
                "class": "level"
              }, [e.level_name])]), _createVNode("div", {
                "class": "name"
              }, [e.card_name])]));
            } else {
              ArrLi.push(_createVNode("div", {
                "class": "openbox-li"
              }, [_createVNode("div", {
                "class": "img"
              }, [_createVNode("img", {
                "src": e.card_icon
              }, null), _createVNode("div", {
                "class": "level"
              }, [e.level_name])]), _createVNode("div", {
                "class": "name"
              }, [e.card_name])]));
            }
          }
        });
        return _createVNode("div", null, [_createVNode("div", {
          "class": "openbox-result"
        }, [_createVNode("div", {
          "class": "openbox-title"
        }, [_createVNode("div", null, [_createTextVNode("\u5361\u724C\u660E\u7EC6")]), _createVNode("div", {
          "class": "num",
          "onClick": () => {
            this.openboxmore();
          }
        }, [_createTextVNode("\u67E5\u770B\u5168\u90E8 ("), orderInfo.card_info.card_total, _createTextVNode(")"), _createVNode("svg", {
          "class": "iconfont svg-icon",
          "aria-hidden": "true"
        }, [_createVNode("use", {
          "xlink:href": "#icon-nestmoreweibo"
        }, null)])])]), _createVNode("div", {
          "class": "openbox-ul-card",
          "onClick": () => {
            this.openboxmore();
          }
        }, [ArrLi])])]);
      }
    })(), (() => {
      const AmountLine = () => {
        const A = [];
        if (orderInfo.amount_info && orderInfo.amount_info.amount_line && orderInfo.amount_info.amount_line.length) {
          for (const item of orderInfo.amount_info.amount_line) {
            A.push(_createVNode("div", {
              "class": "reward_special_price price_box"
            }, [_createVNode("span", null, [item.title]), _createVNode("span", null, [item.value])]));
          }
        }
        return A;
      };
      var TotalAmount = [];
      if (orderInfo.amount_info) {
        TotalAmount = _createVNode("div", {
          "class": "shifu"
        }, [_createVNode("span", null, [orderInfo.amount_info.total_amount_title]), _createVNode("span", {
          "class": "num"
        }, [orderInfo.amount_info.total_amount_value])]);
      }
      return _createVNode("div", {
        "class": "price"
      }, [_createVNode(AmountLine, null, null), _createVNode(TotalAmount, null, null)]);
    })()]);
  }
};