import { createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
// import { ref } from 'vue'
import domain from "@/service/domain";
import ClipboardJS from "clipboard";
import env from "md-base-tools/env";
import { showSuccessToast } from "vant";
import { mdToast } from "../../utils/tools";
export default {
  name: "orderHeader",
  props: {
    orderInfo: Object,
    type: {
      type: String,
      default: "project"
    }
  },
  computed: {
    App() {
      return env.isModianIos() || env.isModianAndroid() || env.isWxApp();
    }
  },
  data() {
    return {
      showAll: false
    };
  },
  setup(props, context) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const orderInfo = props.orderInfo;

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      orderInfo
    };
  },
  methods: {
    back() {
      console.log("back");
      this.$router.back(-1);
    },
    jumpMusic(item) {
      const config = {
        type: "md://album_list?id=" + item.music_id,
        open: true
      };
      this.$store.dispatch("currentlLinkUrlAction", config);
    },
    replaceBr(str) {
      // eslint-disable-next-line no-control-regex
      var reg = new RegExp("\n", "g"); // g,表示全部替换。
      return str.replace(reg, " <br> ");
    },
    show() {
      this.showAll = true;
    },
    goLogistics() {
      if (this.orderInfo.deliver_info.query_type === 2) {
        this.$router.push(`/order/logistics/${this.orderInfo.order_id}`);
      } else if (this.orderInfo.deliver_info.query_type === 1) {
        if (this.orderInfo.deliver_info.post_type === 6) {
          location.href = `${domain.wap}/digital/detail/${this.orderInfo.goods_info[0].nft_stock_hash}.html`;
        } else if (this.orderInfo.deliver_info.post_type === 5) {
          this.jumpMusic(this.orderInfo.deliver_info.music_info);
        } else if (this.orderInfo.deliver_info.post_type === 1) {
          this.$router.push(`/order/logistics/info?express_name=${this.orderInfo.goods_info[0].express_name}&express_no=${this.orderInfo.goods_info[0].express_no}&order_id=${this.orderInfo.order_id}`);
        }
      }
    },
    copy(e) {
      e.stopPropagation();
      document.getElementsByClassName("headerinput")[0].select();
      try {
        if (document.execCommand("copy", false, null)) {
          showSuccessToast("复制成功");
        } else {
          // IOS不支持时候重新初始化Clipboard
          var copyTarget = this.$refs.copyboard;
          var copyboard = new ClipboardJS(copyTarget, {
            text(copyTarget) {
              return copyTarget.getAttribute("copyData");
            }
          });
          copyboard.on("success", () => {
            showSuccessToast("复制成功");
          });
        }
      } catch (err) {
        mdToast(err);
      }
    }
  },
  render() {
    const orderInfo = this.orderInfo;
    const orderContent = () => {
      const A = [];
      if (orderInfo.deliver_info) {
        const B = [];
        if (orderInfo.deliver_info) {
          const C = [];
          if (orderInfo.deliver_info.title_info.title_1) {
            C.push(_createVNode("div", null, [_createVNode("p", null, [orderInfo.deliver_info.title_info.title_1])]));
          }
          if (orderInfo.deliver_info.title_info.title_2) {
            C.push(_createVNode("div", {
              "style": "position:relative"
            }, [_createVNode("p", {
              "class": "logistics_id"
            }, [orderInfo.deliver_info.title_info.title_2, (() => {
              if (orderInfo.deliver_info.title_info.title_copy) {
                return _createVNode("span", {
                  "class": "copy"
                }, [_createTextVNode("\u590D\u5236")]);
              }
            })()]), (() => {
              if (orderInfo.deliver_info.title_info.title_copy) {
                return _createVNode("div", {
                  "class": "copy-box",
                  "onClick": this.copy
                }, [_createVNode("input", {
                  "type": "input",
                  "disabled": true,
                  "class": "headerinput",
                  "ref": "copyboard",
                  "copyData": orderInfo.deliver_info.title_info.title_copy,
                  "value": orderInfo.deliver_info.title_info.title_copy
                }, null)]);
              }
            })()]));
          }
          const classarr = ["logistics_cont"];
          if (orderInfo.deliver_info.title_info.title_copy) {
            classarr.push("logistics_cont_copy");
          }
          B.push(_createVNode("div", {
            "class": classarr,
            "onClick": this.goLogistics
          }, [C]));
        }
        if (orderInfo.deliver_info.query_type && orderInfo.deliver_info.query_type > 0 && !orderInfo.deliver_info.title_info.title_copy) {
          B.push(_createVNode("span", {
            "class": "nestmore",
            "onClick": this.goLogistics
          }, [_createVNode("div", {
            "class": "righticon"
          }, null)]));
        }
        A.push(_createVNode("div", {
          "class": "logistics",
          "onClick": this.goLogistics
        }, [(() => {
          // if (orderInfo.deliver_info.post_type === 6) {
          // const img = require("@assets/images/orderlist/shuzi.png");
          const img = require("@assets/images/orderlist/orderDetailsIcon4.png");
          return _createVNode("img", {
            "class": "musicimg",
            "src": img
          }, null);
          // } else if (orderInfo.deliver_info.post_type === 5) {
          //   const img = require("@assets/images/orderlist/ship.png");
          //   return (
          //     <img class="musicimg" style="position: absolute;" src={img} />
          //   );
          // } else {
          //   return (
          //     <span class="logo">
          //       <svg class="iconfont" aria-hidden="true">
          //         <use xlink:href="#icon-transporttruck"></use>
          //       </svg>
          //     </span>
          //   );
          // }
        })(), B]));
      }
      if (orderInfo.address && (orderInfo.address.address_name || orderInfo.address.address_mobile) || orderInfo.address_info && (orderInfo.address_info.address_name || orderInfo.address_info.address_mobile)) {
        const addressInfo = orderInfo.address_info || orderInfo.address;
        const topCenter = [];
        if (addressInfo.address_name && addressInfo.address_name != "") {
          topCenter.push(_createVNode("span", null, [addressInfo.address_name]));
        }
        if (addressInfo.address_mobile) {
          topCenter.push(_createVNode("span", null, [addressInfo.address_mobile]));
        }
        if (addressInfo.province || addressInfo.city || addressInfo.county || addressInfo.street || addressInfo.address) {
          A.push(_createVNode("div", {
            "class": "userInfo"
          }, [_createVNode("span", {
            "class": "logo"
          }, null), _createVNode("div", {
            "class": "user_cont"
          }, [_createVNode("p", {
            "class": "top"
          }, [topCenter]), _createVNode("p", null, [addressInfo.province, _createTextVNode("\xA0"), addressInfo.city, _createTextVNode("\xA0"), addressInfo.county, _createTextVNode("\xA0"), addressInfo.street, _createTextVNode("\xA0"), addressInfo.address])])]));
        } else {
          A.push(_createVNode("div", {
            "class": "userInfo"
          }, [_createVNode("span", {
            "class": "logo"
          }, null), _createVNode("div", {
            "class": "user_cont"
          }, [_createVNode("p", {
            "class": "top"
          }, [topCenter])])]));
        }
      }
      if (orderInfo.deliver_info && orderInfo.deliver_info.post_msg) {
        if (this.showAll) {
          const P = [];
          P.push(_createVNode("p", null, [_createVNode("label", null, [_createTextVNode("\u5356\u5BB6\u5907\u6CE8")]), _createVNode("span", null, [this.replaceBr(orderInfo.deliver_info.post_msg)])]));
          A.push(_createVNode("div", {
            "class": "express_message"
          }, [_createVNode("div", {
            "class": "cont"
          }, [P])]));
        } else {
          if (orderInfo.deliver_info.post_msg.length > 40) {
            A.push(_createVNode("div", {
              "class": "express_message"
            }, [_createVNode("div", {
              "class": "cont"
            }, [_createVNode("p", null, [_createVNode("label", null, [_createTextVNode("\u5356\u5BB6\u5907\u6CE8")]), orderInfo.deliver_info.post_msg.substr(0, 40), _createTextVNode(" ..."), _createVNode("span", {
              "class": "show",
              "onClick": this.show
            }, [_createTextVNode("\u5C55\u5F00")])])])]));
          } else {
            A.push(_createVNode("div", {
              "class": "express_message"
            }, [_createVNode("div", {
              "class": "cont"
            }, [_createVNode("p", null, [_createVNode("label", null, [_createTextVNode("\u5356\u5BB6\u5907\u6CE8")]), _createVNode("span", null, [this.replaceBr(orderInfo.deliver_info.post_msg)])])])]));
          }
        }
      }
      if (A.length) {
        return _createVNode("div", null, [_createVNode("div", {
          "class": "box"
        }, [A])]);
      }
    };
    return [_createVNode(orderContent, {
      "class": "header_bottom"
    }, null)];
  }
};