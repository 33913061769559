import { createVNode as _createVNode } from "vue";
// import { ref } from 'vue'
import domain from "@/service/domain";
import ClipboardJS from "clipboard";
import env from "md-base-tools/env";
import { showSuccessToast } from "vant";
import { mdToast } from "../../utils/tools";
export default {
  name: "orderHeader",
  props: {
    orderInfo: Object,
    type: {
      type: String,
      default: "project"
    }
  },
  computed: {
    App() {
      return env.isModianIos() || env.isModianAndroid() || env.isWxApp() || env.isWeibo() || !env.isMobile();
    }
  },
  data() {
    return {
      showAll: false
    };
  },
  setup(props, context) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const orderInfo = props.orderInfo;

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      orderInfo
    };
  },
  methods: {
    back() {
      console.log("back");
      this.$router.back(-1);
    },
    jumpMusic(item) {
      const config = {
        type: "md://album_list?id=" + item.music_id,
        open: true
      };
      this.$store.dispatch("currentlLinkUrlAction", config);
    },
    replaceBr(str) {
      // eslint-disable-next-line no-control-regex
      var reg = new RegExp("\n", "g"); // g,表示全部替换。
      return str.replace(reg, " <br> ");
    },
    show() {
      this.showAll = true;
    },
    goLogistics() {
      if (this.orderInfo.deliver_info.query_type === 2) {
        this.$router.push(`/order/logistics/${this.orderInfo.order_id}`);
      } else if (this.orderInfo.deliver_info.query_type === 1) {
        if (this.orderInfo.deliver_info.post_type === 6) {
          location.href = `${domain.wap}/digital/detail/${this.orderInfo.goods_info[0].nft_stock_hash}.html`;
        } else if (this.orderInfo.deliver_info.post_type === 5) {
          this.jumpMusic(this.orderInfo.deliver_info.music_info);
        } else if (this.orderInfo.deliver_info.post_type === 1) {
          this.$router.push(`/order/logistics/info?express_name=${this.orderInfo.goods_info[0].express_name}&express_no=${this.orderInfo.goods_info[0].express_no}&order_id=${this.orderInfo.order_id}`);
        }
      }
    },
    copy(e) {
      e.stopPropagation();
      document.getElementsByClassName("headerinput")[0].select();
      try {
        if (document.execCommand("copy", false, null)) {
          showSuccessToast("复制成功");
        } else {
          // IOS不支持时候重新初始化Clipboard
          var copyTarget = this.$refs.copyboard;
          var copyboard = new ClipboardJS(copyTarget, {
            text(copyTarget) {
              return copyTarget.getAttribute("copyData");
            }
          });
          copyboard.on("success", () => {
            showSuccessToast("复制成功");
          });
        }
      } catch (err) {
        mdToast(err);
      }
    }
  },
  render() {
    const orderInfo = this.orderInfo;
    const orderStatus = () => {
      let icon;
      if (orderInfo.status > 401) {
        icon = () => _createVNode("div", {
          "class": "icon ok"
        }, null);
      }
      switch (orderInfo.status) {
        case 101:
          icon = () => _createVNode("div", {
            "class": "icon ing"
          }, null);
          break;
        case 202:
          icon = () => _createVNode("div", {
            "class": "icon ing"
          }, null);
          break;
        case 201:
        case 302:
          icon = () => _createVNode("div", {
            "class": "icon ok"
          }, null);
          break;
        case 102:
        case 103:
          icon = () => _createVNode("div", {
            "class": "icon warning"
          }, null);
          break;
        case 501:
        case 401:
          icon = () => _createVNode("div", {
            "class": "icon ok"
          }, null);
          break;
      }
      return _createVNode("div", {
        "class": "order_status"
      }, [_createVNode("span", null, [_createVNode(icon, null, null)]), _createVNode("div", {
        "class": "field"
      }, [_createVNode("div", {
        "class": "cont"
      }, [_createVNode("strong", null, [orderInfo.status_name || orderInfo.status_data.status_name])])])]);
      //  (
      //   <div class="order_status">
      //     <span>
      //       <icon />
      //     </span>
      //     <div class="field">
      //       <div class="cont">
      //         <strong>
      //           {orderInfo.status_name || orderInfo.status_data.status_name}
      //         </strong>
      //         <em>
      //           {this.type != "project"
      //             ? orderInfo.status_describe
      //             : orderInfo.status_data.status_describe}
      //         </em>
      //       </div>
      //     </div>
      //   </div>
      // );
    };
    return [_createVNode("div", {
      "class": "bg"
    }, null), _createVNode("div", {
      "class": "bgnav"
    }, null), _createVNode("div", {
      "class": this.App ? "nav topApp" : "nav"
    }, [_createVNode("div", {
      "class": "back",
      "onClick": this.back
    }, null), _createVNode(orderStatus, null, null)])];
  }
};