import { createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
// import { ref } from 'vue'

export default {
  name: "orderHeader",
  props: {
    orderInfo: Object
  },
  data() {
    return {
      showAll: false,
      btnList: []
    };
  },
  setup(props, context) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const orderInfo = props.orderInfo;

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      orderInfo
    };
  },
  methods: {
    replaceBr(str) {
      // eslint-disable-next-line no-control-regex
      var reg = new RegExp("\n", "g"); // g,表示全部替换。
      return str.replace(reg, " <br> ");
    },
    show_dialog() {
      this.$emit("show_dialog", this.btnList);
    },
    op(e) {
      this.$emit("toOp", e);
    }
  },
  render() {
    const orderInfo = this.orderInfo;
    const orderStatus = () => {
      const A = [];
      if (orderInfo.order_btns && orderInfo.order_btns.length || orderInfo.status_data.btn_list && orderInfo.status_data.btn_list.length) {
        // orderInfo.status_data.btn_list.reverse()
        const statusList = JSON.parse(JSON.stringify(orderInfo.order_btns || orderInfo.status_data.btn_list));
        const showBtn = statusList.splice(0, 2).reverse();
        const btnList = statusList;
        this.btnList = btnList;
        A.push(_createVNode("div", {
          "class": "flex_p"
        }, null));
        if (btnList.length) {
          A.push(_createVNode("span", {
            "class": "span",
            "onClick": this.show_dialog
          }, [_createTextVNode("\u66F4\u591A")]));
        }
        showBtn.map(e => {
          A.push(_createVNode("div", {
            "onClick": () => {
              this.op(e.type);
            },
            "class": ["order_btn", e.type]
          }, [e.title]));
        });
        return A;
      }
    };
    if (orderInfo.order_btns && orderInfo.order_btns.length || orderInfo.status_data.btn_list && orderInfo.status_data.btn_list.length) {
      return _createVNode("div", {
        "class": "footer_box"
      }, [_createVNode("div", {
        "class": "footer_cont"
      }, [_createVNode("div", {
        "class": "btn_list"
      }, [_createVNode(orderStatus, null, null)])])]);
    } else {
      return '';
    }
  }
};